<template>
	<div class="main-contents">
		<div class="package">
			<div class="table">
				<!-- 공급기업 지정 프로젝트 지원현황 상세 -->
				<div class="list-box">
					<fixTop :inContent="true" fixClass="top-fix">
						<template v-slot:conts>
							<div class="bt-list mt-10">
								<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="$router.go(-1)">
							</div>
							<span v-if="projectList.length > 0">
								<div class="itv-result-box" v-for="row in projectList" :key="row.projectSeq">
									<div class="prj_ct">
										<div class="mark-box">
											<div class="mark bp" v-if="row.projectStatus == '4'" >제안단계</div>
											<div class="mark ep" v-else-if="row.projectStatus == '2'">수행예정</div>
											<div class="mark cp" v-else-if="row.projectStatus == '9'">완료</div>
											<div class="mark pf" v-else>수행중</div>

											<!-- <div class="mark rc" v-if="row.recrFinishYn == 'N' && row.projectState != '03'">+모 집 중</div>
											<div class="mark cp" v-else-if="row.projectState != 'N' && row.projectState != '03'">모집완료</div> -->
											<div class="mark rc" v-if="row.recrEndDateDiff >= 0 && row.projectStatus != '9'">+모 집 중</div>
											<!-- <div class="mark cp" v-else>모집완료</div> -->
											<div class="regDate">등록일자<span style="margin-left: 10px;">{{row.regYyyymmdd | date('yyyy. MM. dd')}}</span></div>
										</div>
										<div class="prj_outline">
											<div class="top">
												<div class="outline">
													<a v-if="row.projectDivCd == '11' || row.projectDivCd == '12'" class="label">프로젝트명</a>
													<a v-else class="mark_sco">공급기업 지정</a>
													
													<div class="value prj prj-tit-hover" style="width: 750px;" @click="clickProjectInfo(row.projectSeq, row.projectStatus)">{{row.projectNm}}</div>
												</div>
												<div class="outline">
													<div class="label ivl03" style="margin-right: -3px;">개요</div>
													<div class="value text">{{row.projectDtlDesc}}</div>
												</div>
												<div class="outline">
													<div class="label ivl02">고 객 사</div>
													<div style="display: inline-flex; width: 750px;">
														<div class="prj_outline_clientname">
															{{row.clientNm}}
														</div>
														<div class="prj_outline_clientloc">
															<img src="/images/location.png" alt="위치" />{{row.projectLocCont}}
														</div>
													</div>
												</div>
												<div class="outline">
													<div class="label ivl05">산업분야</div>
													<div class="value" style="display: inline-block !important;">
														<SelectComp type="text" cdId="PRO105" :value="row.indtypeClassCd.substr(0,1)"/> / <SelectComp type="text" cdId="PRO143" :value="row.indtypeClassCd"/>
													</div>
												</div>
											</div>
											
											<div class="outline">
												<div class="label ivl02">수 행 사</div>
												<div class="value">{{row.corpNm}}</div>
											</div>
											<div class="outline">
												<div class="label">개 발 기 간</div>
												<div class="value">{{row.projectStartYyyymmdd | date('yyyy.MM.dd')}} - {{row.projectEndYyyymmdd | date('yyyy.MM.dd')}}</div>
											</div>
											<div class="outline">
												<div class="label ivl05">직무분야</div>
												<div class="value">
													<ul class="duty-list">
														<li v-for="(duty, index) in row.dutyList" :key="index">
															<span>
																<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
															</span>
														</li>
													</ul>
												</div>
											</div>
											<div class="outline">
												<div class="label ivl05">개발기술</div>
												<div class="value text">{{row.devTechCont}}</div>
											</div> 
										</div>
										
										<!--고객CI/지원자정보-->
										<div class="client_info">
											<div class="ci_box">
												<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+row.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
											</div>
											<div class="deadline">
												<span v-if="row.recrEndDateDiff >= 0">
													<img class="period" src="/images/tec/prj/date.png" alt="마감기간"/> 마감 <span class="period">{{row.recrEndDateDiff}}일</span> 전
												</span>
												<img class="member" src="/images/tec/prj/member_sm.png" alt="참여인재수"/> <span class="ivl06">참여인재 </span><span class="member">{{row.mberCnt}}명</span>
											</div>
										</div>
									</div>

									<!-- 참여인재 -->
									<div v-if="projectTecMberList.length > 0" 
											class="Board type3 itv show dco">
										<div v-if="!projectIngEnd(row) && !projectIng" class="humaninfo-box dco">
											<div class="btn-humaninfo" @click="checkMberInfoDown(row)">
												<img src="/images/resume_download_01.png"/>
												<p>선택한 기술인재 이력 다운로드</p>
											</div>
											<div v-if="selectedIndex.length == 0" class="btn-humaninfo" @click="allMberInfoDown(row)">
												<img src="/images/resume_download_01.png"/>
												<p>전체 기술인재 이력 다운로드</p>
											</div>
											<div v-else class="btn-humaninfo">
												<img src="/images/resume_download_02.png"/>
												<p>전체 기술인재 이력 다운로드</p>
											</div>
										</div>
										<table class="Board_type3 dco">
											<colgroup>
												<col v-if="!projectIngEnd(row) && !projectIng" width="3%">
												<col width="3%">
												<col width="5%">
												<col width="5%">
												<col width="3%">
												<col width="4%">
												<col width="6%">
												<col width="14%">
												<col width="12%">
												<col width="16%">
												<col width="7%">
												<col v-if="projectIngEnd(row) || projectIng" width="10%">
											</colgroup>
											<thead>
												<tr>
													<th v-if="!projectIngEnd(row) && !projectIng"><input type="checkbox" style="width:14px" @click="allCheck()" v-model="checkBox"></th>
													<th>NO</th>
													<th>평균<br/>평가등급</th>
													<th>성명</th>
													<th>성별</th>
													<th>년생</th>
													<th>투입등급</th>
													<th>참여소속사</th>
													<th>담당분야</th>
													<th>참여기간</th>
													<th v-if="projectIngEnd(row) || projectIng" colspan="2">참여상태</th>
													<th v-else>참여상태</th>
													
												</tr>
											</thead>
											<tbody>
												<tr v-for="(mber, index) in projectTecMberList" :key="mber.mberSeq">
													<td v-if="!projectIngEnd(row) && !projectIng" class="score checkbox dco">
														<SelectComp type="checkbox" v-model="selectedIndex" :list="index+ ':'" @change="check()"/>
													</td>
													
													<td class="score">{{(pageInfo.pageUnit * (pageInfo.pageIndex-1)) + index + 1 }}</td>
													<td class="score">
														<grade-img type="TOT_EVAL_ADM" :grade="mber.totEvalGradeCd " :specialYn="mber.specialGradeYn">
															<template v-slot:smark><div class="s-mark"><img src="/images/admin/s-mark.png" alt="스페셜마크"></div></template>
														</grade-img>
													</td>
													<td class="score name" @click="clickTecMber(mber.mberSeq)">{{mber.mberNm}} &#9654;</td>
													<td class="score">{{mber.gender | gender}}</td>
													<td class="score">{{mber.birthYyyymmdd | birthYear}}</td>
													
													<td class="score"><grade-img type="LICENSE_TECH_DCO" :grade="mber.projectJoinTechGradeCd"/></td>
													<td class="score">
														<span v-if="mber.corpNm != undefined">{{mber.corpNm}}</span>
														<span v-else class="color-gray">소속없음</span>
													</td>
													<!-- <td class="score"><SelectComp type="text_comma_split" cdId="PRO142" :value="mber.dutyCdList"/></td> -->
													<td class="score">{{mber.workCont}}</td>
													<td class="score">{{mber.joinStartEndYyyymmdd}}</td>
													<!-- <td class="score resume"><div class="btn" @click="changeJoinStartEndYyyymmdd(project, mber.mberSeq)">참여기간 변경 &#9654;</div></td> -->
													<td class="score">
														<!-- <div class="state pf_ep"/> -->
														<div :class="mber.projectRecrStatus == '2' ? 'state pf_ep' : mber.projectRecrStatus == '1' ? 'state pf' : mber.projectRecrStatus == '0' ? 'state rq' : 'state cp'"/>
														<SelectComp type="text" list="0:중도철수,1:참 여 중,2:참여예정,9:참여완료" :value="mber.projectRecrStatus"/>
													</td>
													<td v-if="projectIngEnd(row) || projectIng" class="score">
														<div v-if="(['0', '9'].indexOf(mber.projectRecrStatus) >= 0)" class="evl_btn" :class="mber.evalFinishYn == 'Y' ? 'show' : ''" @click="goEval(row.projectSeq, mber.mberSeq)">
															<span v-if="mber.evalFinishYn == 'Y'">평가보기</span>
															<span v-else>평가하기</span> &#9654;
														</div>
														<div v-else>-</div>
													</td>
												</tr>						
											</tbody>
										</table>
										<pagingComp :pageInfo="pageInfo" @page-click="goPage" :classNm="'mb-0'"/>
									</div>

								</div>
							</span>

							<!-- 요청한 인터뷰 결과가 없을 경우 -->
							<div class="project_box type" v-else>
								<div class="prj_pf none">
									요청하신 인터뷰 결과가 없습니다!
								</div>
							</div>

							<!-- 페이징부분 -->
							<!-- <pagingComp :pageInfo="pageInfo" @page-click="goPage" /> -->
						</template>
					</fixTop>

					<!-- footer -->
					<!-- <footer-layout type="left"/> -->
				</div>

				<!-- 상세보기 -->
				<tec-profile :tecMberSeq="tecMberSeq" v-model="detailObj" @profileClose="profileClose()"/>
			</div>
		</div>
	</div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from '@/components/highpro/GradeImg.vue';
import tecProfile from "@/components/highpro/TecProfile.vue";
// import footerLayout from "@/components/layout/footer.vue";
import fixTop from "@/components/FixTop.vue";

export default {
	data() {
		return {
			projectList : [],
			projectTecMberList : [],
			pageInfo : {
				pageUnit : 15,
				pageIndex : 1,
				pageSize : 10
			},

			corpdiv : this.$store.state.userInfo.mberDivCd,
			

			tecMberSeq : '',
			seletedIndex : 0,
			detailObj : {},

			nowYyyymmdd : new Date().format("yyyyMMdd"),
			selectedIndex : [],

			checkBox : false,
			
		}
	},

	components : {
		gradeImg, tecProfile, fixTop, pagingComp
		// , footerLayout
	},

	beforeMount() {
		// console.log(this.$route.params);
		if(this.$route.params.projectSeq != null || this.$route.params.projectSeq != '') {
			this.input = this.$route.params.input;
			this.input.projectSeq = this.$route.params.projectSeq;
			this.input.projectDetail = 'Y';
			// console.log(this.input);
		}
		// this.getProjectDetail();
		// console.log(this.$route.params);
		this.projectList.push(this.$route.params.project);
		this.projectSeqTecMber = this.projectList[0].projectSeq;
		this.getTecMberList(this.projectSeqTecMber);
	},

	mounted(){
        // this.$emit('setViewConfig', {'footer': 'left', 'quickMenu':false})
		// console.log(this.projectIng);
    },
    destroyed(){
        this.$emit('clearViewConfig')
    },

	methods : {
		// getProjectDetail() {
		// 	this.$.httpPost('/api/prj/dco/pmg/getProjectDetail', this.input)
		// 		.then(res => {
		// 			// console.log('getMyProjectList RESULT', res.data);

		// 			this.projectList 		= res.data.list;
		// 			// this.pageInfo 			= res.data.pageInfo;
		// 			// this.projectCountMap	= res.data.projectCountMap;
		// 			// this.unEvalCount		= res.data.projectCountMap.projectIngUnEvalCount + res.data.projectCountMap.projectEndUnEvalCount;

		// 			for(var i in this.projectList){
						
		// 				if(this.projectList[i].dutyFieldCont){
		// 					var tempArr = this.projectList[i].dutyFieldCont.split(',');
		// 					this.projectList[i].dutyList = [];

		// 					for(var j in tempArr){
		// 						this.projectList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
		// 														   dutyFieldCd  : tempArr[j]});
		// 					}
		// 				}

		// 			}

		// 			this.projectSeqTecMber = '';

		// 			if(this.projectList.length > 0 && this.projectList[0].mberCnt > 0) {
		// 				// 참여인재 조회
		// 				// this.projectList[0].isOpenTecMber = true;
		// 				this.projectSeqTecMber = this.projectList[0].projectSeq;
		// 				this.getTecMberList(this.projectSeqTecMber);
		// 			}
		// 			// console.log(this.projectList);
		// 		})
		// 		.catch(err => {
		// 			// console.log('============================='); 
		// 			// console.log(err);
		// 			// console.log(err.response);
		// 			// console.log('============================='); 

		// 			alert(err.response.data.error_description);
		// 		});
		// },

		// 프로젝트 상세보기 팝업
		clickProjectInfo(projectSeq, projectRecrStatus) {
			var param = {
				projectSeq : projectSeq,
				projectRecrStatus : projectRecrStatus
			};
			this.$.popup('/dco/pmg/prj/PRJ305P01', param, {width:0, height:0})
				// .then(res => {})
				// .catch(err => {})
				;
		},

		getTecMberList(projectSeq) {
			this.checkBox = false;
			this.selectedIndex = [];
			var input = {
				projectSeq : projectSeq,
				projectDivCd : this.input.projectDivCd,
			};

			input.pageIndex = this.pageInfo.pageIndex;
			input.pageUnit = this.pageInfo.pageUnit;
			input.pageSize = this.pageInfo.pageSize;

			this.$.httpPost('/api/prj/sco/choice/getProjectTecMberList', input)
				.then(res => {
					// console.log(res.data);
					this.projectTecMberList	= res.data.list;
					this.projectSeqTecMber = projectSeq;
					this.pageInfo = res.data.pageInfo;

				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		allCheck() {
			if(this.checkBox){	
				this.selectedIndex = [];
			} else {
				this.selectedIndex = [];
				for(var i = 0; i < this.projectTecMberList.length; i++)
				this.selectedIndex.push(String(i));
			}
			// console.log("allCheck : " + this.selectedIndex);
		},

		check() {
			if(this.checkBox && this.selectedIndex.length != this.projectTecMberList.length) {
				this.checkBox = false;
			}

			// console.log("Check : " + this.selectedIndex);
		},

		checkMberInfoDown(project) {
			if(this.selectedIndex.length <= 0 ) {
				alert("기술인재가 선택되지 않았습니다.");
				// return;
			} else {			

				var downloadMberList = [];

				for(var i of this.selectedIndex) {
					// console.log(this.projectTecMberList[i]);
					downloadMberList.push(this.projectTecMberList[i]);
				}

				var param = {
					projectSeq : project.projectSeq,
					projectNm : project.projectNm,
					mberList : downloadMberList,
					projectDivCd : project.projectDivCd
				}

				this.$.fileDownload ('/api/prj/dco/pmg/getPrjMberFileDown', param)
					.then(() => {
						// console.log("res : " + res);
					})
					.catch(err => {
						// console.log('============================='); 
						// console.log(err);
						// console.log(err.response);
						// console.log('============================='); 

						alert(err.response.data.error_description);
					});
				}
			
		},

		allMberInfoDown(project) {
			
			var param = {
				projectSeq : project.projectSeq,
				projectNm : project.projectNm,
				// mberList : downloadMberList,
				projectDivCd : project.projectDivCd
			}

			this.$.fileDownload ('/api/prj/dco/pmg/getPrjAllMberFileDown', param)
				.then(() => {
					// console.log("res : " + res);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
				

		},

		goPage(page) {
			// console.log(page);
			this.pageInfo.pageIndex = page;
			this.getTecMberList(this.projectSeqTecMber);
		},

		clickTecMber(mberSeq){
			// this.$router.push({name: 'SRH301M01', params : {mberSeq : mberSeq}});
			this.tecMberSeq = mberSeq;
        },

		goEval(projectSeq, mberSeq) {
			//alert("평가하기 이동 projectSeq : " + projectSeq + ", mberSeq : " + mberSeq);
			this.$router.push({name:'PRJ304M01', params:{projectSeq : projectSeq, indMberSeq : mberSeq}});
		},

		// 기술인재 상세 닫기
		profileClose() {
			this.tecMberSeq = '';
			this.detailObj = {};
		},
	
	},
	computed : {
		projectIngEnd() {
			return (project) => {
				if( ((project.projectStatus == '1' && project.recrEndDateDiff < 0) || project.projectStatus == '9') ) {
					return true;
				} else {
					return false;
				}
			}
		},

		projectIng() {
			if(this.$route.params.projectIng) {
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>